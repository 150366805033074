import React from 'react'

import { LinkProps } from '../Link/types'
import { Color, TextProps } from '../Text'
import { Text } from '../Text'
import * as S from './styles'

type Params = {
  text: string
  url?: string
  onclick?: () => void
  current?: boolean
}

type BreadCrumbsConfigs = {
  separator?: React.ReactNode
  textProps?: Partial<Omit<TextProps, 'children'>>
  variant?: 'default' | 'deprecated'
  currentCollor?: Color
}

export type BreadCrumbsProps = {
  iconFirst?: {
    icon: React.ReactNode
    url: string
  }
  params: Params[]
  configs?: BreadCrumbsConfigs
}

const defaultConfig: BreadCrumbsConfigs = {
  variant: 'deprecated',
  separator: <S.Icon />,
  currentCollor: 'typography',
  textProps: {
    color: 'typography',
    variant: 'body-3',
    colorWeight: 500,
  },
}

const BreadCrumbsComponent = ({ iconFirst, params, configs = defaultConfig }: BreadCrumbsProps) => (
  <S.Container role="navigation">
    {iconFirst && (
      <S.IconFirst>
        <S.Link
          target="_self"
          {...((configs.textProps || defaultConfig.textProps) as LinkProps)}
          href={iconFirst.url}
        >
          {iconFirst.icon}
        </S.Link>
      </S.IconFirst>
    )}
    {params
      .map((item) => (
        <S.Item>
          {item.url ? (
            <S.Link
              target="_self"
              {...((configs.textProps || defaultConfig.textProps) as LinkProps)}
              nVariant={configs.variant}
              href={item.url}
            >
              {item.text}
            </S.Link>
          ) : (
            <Text
              {...((configs.textProps || defaultConfig.textProps) as TextProps)}
              style={{
                marginLeft: configs.variant === 'default' ? '16px' : 0,
                cursor: item.onclick ? 'pointer' : 'default',
              }}
              color={item.current ? 'primary' : configs.textProps?.color || 'typography'}
              onClick={item.onclick || undefined}
            >
              {item.text}
            </Text>
          )}
        </S.Item>
      ))
      .reduce((previousItem, currentItem) => (
        <>
          {previousItem}
          <S.Item variant={configs.variant}>{configs.separator || defaultConfig.separator}</S.Item>
          {currentItem}
        </>
      ))}
  </S.Container>
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/pages-utils-breadcrumbs-since-2-4--primary)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3469&t=foeGVqCaAdQZDkKU-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { BreadCrumbs } from '@interco/inter-ui/components/BreadCrumbs'
 *
 * export const MyPage = () => {
 *   return (
 *     <BreadCrumbs
 *       params={[
 *         { text: 'First Item' },
 *         { text: 'Last Item' },
 *       ]}
 *     />
 *   )
 * }
 * ```
 */
export const BreadCrumbs = React.memo(BreadCrumbsComponent)
