export var WbSessionActions;
(function (WbSessionActions) {
    WbSessionActions["GET_USER_INFO"] = "getUserInfo";
    WbSessionActions["GET_PERMISSIONS"] = "getPermissions";
    WbSessionActions["REQUEST_PERMISSION"] = "requestPermission";
    WbSessionActions["GET_APP_INFO"] = "getAppInfo";
    /** @description action que escuta a ação de voltar do nativo. Deve ser usada em conjunto com addWebviewEventListener */
    WbSessionActions["GO_BACK"] = "GO_BACK";
    WbSessionActions["REQUEST_GO_BACK"] = "requestGoBack";
    WbSessionActions["OPEN_DEEPLINK"] = "openDeeplink";
    WbSessionActions["REQUEST_ANALYTICS"] = "requestAnalytics";
    WbSessionActions["EDIT_TOOLBAR"] = "editToolbar";
    WbSessionActions["SHARE_CONTENT"] = "shareContent";
    WbSessionActions["REQUEST_GO_BABI"] = "sendToBabi";
    WbSessionActions["OPEN_NATIVE_BROWSER"] = "openNativeBrowser";
    WbSessionActions["CALL_PHONE_NUMBER"] = "callPhoneNumber";
    WbSessionActions["OPEN_LOCATION_MAP"] = "openLocationMap";
    WbSessionActions["GET_USER_LOCATION"] = "getUserLocation";
    WbSessionActions["ON_BACKGROUND"] = "onBackground";
    WbSessionActions["ON_FOREGROUND"] = "onForeground";
    WbSessionActions["RESET_SESSION_TIME"] = "resetSessionTime";
    WbSessionActions["SAVE_ON_STORAGE"] = "saveOnStorage";
    WbSessionActions["GET_FROM_STORAGE"] = "getFromStorage";
    WbSessionActions["COPY_TO_CLIPBOARD"] = "copyToClipboard";
    WbSessionActions["DISPLAY_PDF"] = "displayPdf";
})(WbSessionActions || (WbSessionActions = {}));
export var WbHttpActions;
(function (WbHttpActions) {
    WbHttpActions["REQUEST_API"] = "requestAPI";
})(WbHttpActions || (WbHttpActions = {}));
export var WbAuthorizationActions;
(function (WbAuthorizationActions) {
    WbAuthorizationActions["REQUEST_ISAFE"] = "requestISafe";
})(WbAuthorizationActions || (WbAuthorizationActions = {}));
export var WbWalletActions;
(function (WbWalletActions) {
    WbWalletActions["OPEN_WALLET"] = "openWallet";
    WbWalletActions["CONTRACT_WALLET"] = "contractWallet";
})(WbWalletActions || (WbWalletActions = {}));
