import styled, { css } from 'styled-components'

import ChevronRight from '@interco/icons/orangeds/XL/chevron-right'

import { Link as UILink } from '../Link'

type Variant = 'default' | 'deprecated' | undefined

export const Container = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  width: fit-content;
`

export const Item = styled.div<{ variant?: Variant }>`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
  }
  svg {
    margin-top: 3px;
    margin-left: 3px;
    ${(props) =>
      props.variant === 'default'
        ? css`
            margin: 0 16px;
          `
        : null}
  }
`

export const Icon = styled(ChevronRight).attrs({
  width: 14,
  height: 14,
})`
  path {
    stroke: var(--gray300);
    color: var(--gray300);
  }
  color: var(--gray300);
`

export const Link = styled(UILink)<{ nVariant?: Variant }>`
  ${(props) =>
    props.nVariant === 'default'
      ? css`
          color: var(--primary500);
          font-weight: bolder;
        `
      : css`
          color: var(--typography500);
          font-weight: 400;
        `}
`

export const IconFirst = styled.div`
  margin-right: 16px;
  svg {
    width: 24px;
    height: 24px;
    color: var(--primary500);
  }
`
